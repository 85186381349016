import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import Hero from 'bundles/App/pages/CategoryPage/Hero/Hero';
import Footer from 'styleguide/components/Footer/Footer';
import AdditionalInfo from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/AdditionalInfo';
import FAQ from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/FAQ/FAQ';
import Meta from 'styleguide/components/Meta/Meta';
import ProductCardsRow from 'bundles/App/pages/CategoryPage/row/ProductCardsRow';
import CallToActionRow from 'bundles/App/pages/CategoryPage/row/CallToActionRow';
import { CmsCategoryPage } from 'contexts/AppContext/types';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { trackCmsPage } from 'api/CmsPages';
import PromotionBanner from 'styleguide/components/PromotionBannner/PromotionBanner';
import Articles from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/Articles/Articles';
import Reviews from 'styleguide/components/Reviews/Reviews';
import useCms from 'utils/hooks/useCms';
import { useLocation } from 'react-router-dom';

const CategoryPage = () => {
  const { currentUser } = React.useContext(UserContext);
  const cms = useCms<CmsCategoryPage>();
  const location = useLocation();

  const getBanner = () => {
    let banner = null;

    if (!!cms.displayPromotion && !!cms.displayPromotion.showBanner) {
      banner = cms.displayPromotion;
    }
    return banner;
  };

  const promotionBanner = getBanner();
  const serviceCardsRow = cms.rows.filter(row => row.type === 'Comfy::Cms::Page::Row::ServiceCards')[0];
  const hero = cms.hero;
  React.useEffect(() => {
    if (currentUser) {
      trackCmsPage(cms.id);
    }
  }, [cms, currentUser]);

  return (
    <>
      <Meta
        title={cms.metaData.metaTitle}
        description={cms.metaData.metaDescription}
        canonical={cms.metaData.metaCanonicalLink}
        keywords={cms.metaData.metaKeywords}
        robots={location.pathname === '/booklets' && ['noindex', 'follow']}
      />
      {!!hero && (
        <Hero
          title={hero.title}
          backgroundImage={hero.backgroundImageUrl}
          buttonText={hero.btnText}
          buttonLink={hero.btnLink}
          buttonScrollTarget={hero.btnScrollTarget}
          content={hero.content}
          onClick={(target: string) => {
            const elem = document.getElementById(target);
            if (elem) {
              elem.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        />
      )}
      <Grid.Container>
        <Grid>
          {!!promotionBanner && (
            <Grid.Row className="mb-4">
              <Grid.Col md={1} />
              <Grid.Col md={10}>
                {promotionBanner ? <PromotionBanner displayPromotion={promotionBanner} /> : null}
              </Grid.Col>
              <Grid.Col md={1} />
            </Grid.Row>
          )}
          <h2
            id="shop-section"
            className="my-8 mx-auto mb-3 scroll-mt-24 text-left font-hvBold text-xl leading-7 lg:text-4xl lg:leading-10 2xl:!mx-0 2xl:pl-8"
          >
            {cms.subtitle}
          </h2>
        </Grid>
      </Grid.Container>
      {cms.rows.map(row => (
        <>
          {row.type === 'Comfy::Cms::Page::Row::ProductCards' && <ProductCardsRow productCards={row} />}
          {row.type === 'Comfy::Cms::Page::Row::CallToAction' &&
            serviceCardsRow.type === 'Comfy::Cms::Page::Row::ServiceCards' && (
              <CallToActionRow callToAction={row} serviceCardsRow={serviceCardsRow} />
            )}
          {row.type === 'Comfy::Cms::Page::Row::InfoBlock' && <Articles infoBlock={row} />}
        </>
      ))}
      <Reviews withoutBorder />
      <AdditionalInfo
        FAQ={<FAQ questions={cms.faqs} />}
        SEOBlockTitle={cms.seoHeader}
        SEOBlock={cms.seoContent}
      />
      <Footer />
    </>
  );
};

export default CategoryPage;
