import * as React from 'react';
import cn from 'classnames';
import IconMegaphone from 'styleguide/icons/IconMegaphone';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import Tooltip from 'styleguide/components/Tooltip/Tooltip';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import { DisplayPromotion } from 'contexts/AppContext/types';

interface Props {
  displayPromotion: DisplayPromotion;
}

const bannerOutlineColors = {
  blue: 'text-blue border-2 border-blue',
  red: 'text-orange border-2 border-orange',
  white: 'text-white border-2 border-white',
  black: 'text-black border-2 border-black',
  green: 'text-green border-2 border-green',
  gray: 'text-gray-500 border-2 border-gray-500',
};

const PromotionBanner = ({ displayPromotion: { outlineStyle, color }, displayPromotion }: Props) => (
  <div className="flex justify-center mt-6">
    <div
      className={cn(
        'flex justify-center bg-blue text-white py-1 px-4 items-center flex-col rounded-lg',
        outlineStyle && 'bg-white',
        color && bannerOutlineColors[color],
      )}
    >
      <div className="flex items-center mt-4 px-4">
        <IconMegaphone className="min-w-[15px]" direction="left" color={color || 'white'} />
        <HtmlContent className="px-4" content={displayPromotion.topRow} />
        <IconMegaphone className="min-w-[15px]" direction="right" color={color || 'white'} />
      </div>
      {displayPromotion.secondRow ? (
        <div className="px-4 flex items-center my-2">
          <HtmlContent className="px-4" content={displayPromotion.secondRow} />
          {displayPromotion.tooltip ? (
            <>
              <IfDesktop className="basis-0">
                <Tooltip
                  className="hover:cursor-pointer"
                  iconColor={color || 'white'}
                  type="info"
                  position="bottom"
                  contentToolTip={<HtmlContent content={displayPromotion.tooltip} />}
                />
              </IfDesktop>
              <IfTabletOrMobile className="basis-0">
                <Tooltip
                  className="hover:cursor-pointer"
                  iconColor={color || 'white'}
                  type="info"
                  position="right-end"
                  contentToolTip={<HtmlContent content={displayPromotion.tooltip} />}
                />
              </IfTabletOrMobile>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

export default PromotionBanner;
